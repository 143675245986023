import React from 'react';
import InfoRow from '../../components/apukomponentit/yritysrivi';

const handlePositions = (asemat, locale) => {
  const localizedPositions = asemat.map(asema => (locale === 'fi' ? asema.AsemaFi : asema.AsemaSv));
  return localizedPositions.join(', ');
};

const oikeushenkiloComponent = ({oikeushenkilot, locale}) =>
  oikeushenkilot.map((oikeushenkilo, index) => {
    const positions = handlePositions(oikeushenkilo.Asemat, locale);
    if (index === 0) {
      return (
        <InfoRow
          key={index}
          labelId="company-legal_persons"
          arvo={`${oikeushenkilo.Nimi}, ${positions}`}
        />
      );
    }
    return <InfoRow key={index} arvo={`${oikeushenkilo.Nimi}, ${positions}`} />;
  });

export default oikeushenkiloComponent;
