import React from 'react';
import PropTypes from 'prop-types';

import '../styles/common.css';
import InfoBlock from '../components/infoblock';
import {ReactComponent as Icon1} from '../images/letter.svg';
import {ReactComponent as Icon2} from '../images/document.svg';
import {ReactComponent as Icon3} from '../images/coins.svg';

class Home extends React.Component {
  render() {
    return (
      <div className="info-blocks">
        <InfoBlock headingLevel="h2" title="home-debitors_title" desc="home-debitors_desc">
          <Icon1 />
        </InfoBlock>
        <InfoBlock headingLevel="h2" title="home-client_title" desc="home-client_desc">
          <Icon2 />
        </InfoBlock>
        <InfoBlock headingLevel="h2" title="home-agency_title" desc="home-agency_desc">
          <Icon3 />
        </InfoBlock>
      </div>
    );
  }
}

Home.propTypes = {
  organisaatioId: PropTypes.number
};

export default Home;
