import React from "react";
import { ReactComponent as SearchIcon } from "../images/search.svg";
import { useIntl } from "react-intl";

const SearchInput = props => {
  const intl = useIntl();

  return (
    <div className="search-container-input-container">
      <SearchIcon />
      <input
        id="searchbox"
        type="search"
        onChange={props.onChange()}
        placeholder={intl.formatMessage({
          id: "search_bar-search_for_company",
        })}
      />
    </div>
  );
};
export default SearchInput;