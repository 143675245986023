import React, {Component} from 'react';
import {Container} from 'react-bootstrap';
import CompanySanctions from '../components/CompanySanctions';
import moment from 'moment';
import webClient from '../api/webClient';
import InfoRow from '../components/apukomponentit/yritysrivi';
import {ReactComponent as SearchIcon} from '../images/help.svg';
import '../styles/company.css';
import {FormattedMessage} from 'react-intl';
import {Oikeushenkilot, Toimipaikat} from './companyView';

class Company extends Component {
  state = {
    yritys: null,
    loading: false
  };

  componentDidMount() {
    this.fetchCompanyInfo();
  }

  componentDidUpdate(prevProps) {
    if (this.props.companyId !== prevProps.companyId) {
      this.fetchCompanyInfo();
    }
  }

  fetchCompanyInfo = () => {
    this.setState({loading: true});
    webClient
      .post('Yrityksenperustiedot/Hae', {yritysid: this.props.companyId})
      .then(response => {
        this.setState({
          yritys: response.data,
          loading: false
        });
      })
      .catch(error => {
        this.setState({loading: false});
      });
  };

  render() {
    if (this.state.loading) {
      return (
        <h4>
          <FormattedMessage id="general_loading" defaultMessage="Ladataan..." />
        </h4>
      );
    }

    if (this.state.yritys === null) {
      return (
        <p>
          <FormattedMessage id="company-company_not_found" defaultMessage="Yritystä ei löytynyt" />
        </p>
      );
    }
    const result = this.state.yritys;
    return (
      <section className="company-info-container-wrapper company-info-container">       
          <Container className="company-info-container_rows">
            <button autoFocus className="company-info__close-button" onClick={this.props.closeCompanyInfo}>
              <FormattedMessage id="company-close_info" defaultMessage="Sulje lisätiedot" />
            </button>
            <h1>{result.Toiminimi}</h1>
            <InfoRow labelId="company-company_aux_name" arvo={result.Aputoiminimi} />
            <InfoRow labelId="company-company_businessID" arvo={result.YTunnus} />
            {result.RekisterointiPvm && (
              <InfoRow
                labelId="company-registry_date"
                arvo={moment(result.RekisterointiPvm).format('DD.MM.YYYY')}
              />
            )}
            <Toimipaikat toimipaikat={result.Toimipaikat} />
            {result.VastaavaHenkilo.map((vh, index) => {
              if (index === 0) {
                return <InfoRow key={index} labelId="company-responsible" arvo={vh.Nimi} />;
              }
              return <InfoRow key={index} arvo={vh.Nimi} />;
            })}
            <Oikeushenkilot oikeushenkilot={result.Oikeushenkilot} locale={this.props.locale} />
            <CompanySanctions sanktiot={this.state.yritys.Sanktiot} loading={this.state.loading} />
          </Container>
          <div className="email-address">
            <SearchIcon />
            <FormattedMessage
              id="company-contact"
              defaultMessage="Onko tiedoissa virhe? Ota yhteyttä: "
            />
            <a href="mailto:elinkeinot.etela@avi.fi">elinkeinot.etela@avi.fi</a>
            <br />
            <FormattedMessage
              id="company-changed_info"
              defaultMessage="Muuttuneet tiedot voit ilmoittaa muutosilmoituslomakkeella osoitteeseen "
            />
            <a href="mailto:kirjaamo.etela@avi.fi" target="_blank" rel="noopener noreferrer">
              <FormattedMessage
                id="company-changed_info_email"
                defaultMessage="kirjaamo.etela@avi.fi."
              />
            </a>

            <div className="form-info">
              <FormattedMessage
                id="company-find_form"
                defaultMessage="Lomake löytyy aluehallintoviraston verkkosivustolta "
              />
              <a
                href="https://www.avi.fi/web/avi/elinkeinot2#Perint%C3%A4"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="company-find_form_link" defaultMessage="täältä." />
              </a>
            </div>
          </div>
      </section>
    );
  }
}

export default Company;
