import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

const resultCounter = ({counter}) => (
  <div className="show-result-counter">
    <span>
      <FormattedMessage id="result_counter-result_count1" defaultMessage="Hakuehdoilla löytyi " />
      {counter}
      <FormattedMessage id="result_counter-result_count2" defaultMessage=" yritystä" />
    </span>
  </div>
);

resultCounter.propTypes = {
  counter: PropTypes.number.isRequired
};

export default resultCounter;
