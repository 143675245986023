import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {ReactComponent as HelpIcon} from '../images/help.svg';
import {
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  OverlayTrigger
} from 'react-bootstrap';
import SearchInput from './SearchInput';
import CompanyCounter from './CompanyCounter';
import '../styles/searchBar.css';
import {FormattedMessage} from 'react-intl';

class Search extends Component {
  state = {
    searchValue: '',
    searchType: 'registered',
    active: false,
    selectedValue: ''
  };

  componentDidMount() {
    this.toggleClass('registered')
  }

  onChange = e => this.setState({searchValue: e.target.value});

  getToggleValue = e => this.setState({searchType: e.target.value});

  onSubmit = (value, type) => {
    this.props.history.push({
      pathname: '/yritykset',
      search: `?nameorid=${value}&Type=${type}`
    });
    this.props.onSearch(value, type);
  };

  toggleClass = value => this.setState({active: true, selectedValue: value});

  OverlayToggleButton = ({value, messageId, defaultMessage, tooltipMessageId}) => (
    <OverlayTrigger
      overlay={
        <Tooltip id={value}>
          <FormattedMessage id={tooltipMessageId} />
        </Tooltip>
      }
      trigger={['hover', 'focus']}
    >
      <ToggleButton
        value={value}
        type="radio"
        name="type"
        variant="outline-light"
        onClick={() => this.toggleClass(value)}
        className={this.state.active == true && this.state.selectedValue == value ? 'active' : ''}
      >
        <FormattedMessage id={messageId} defaultMessage={defaultMessage} />
      </ToggleButton>
    </OverlayTrigger>
  );

  render() {
    return (
      <div className="search-container">
        <form
          onSubmit={e => {
            e.preventDefault();
            this.onSubmit(this.state.searchValue, this.state.searchType);
          }}
        >
          <label style={{display: 'none'}} htmlFor="searchbox">
            <FormattedMessage id="search_bar-input_placeholder" />
          </label>
          <SearchInput onChange={() => this.onChange} />
        </form>
        <ButtonToolbar
          className="search-container-input-container__toggle"
          onChange={this.getToggleValue}
        >
          <ToggleButtonGroup type="radio" name={'type'} value={this.state.searchType}>
            {this.OverlayToggleButton({
              value: 'registered',
              messageId: 'search_bar-registrations',
              defaultMessage: 'Rekisteröidyt',
              tooltipMessageId: 'search_info-registrations'
            })}
            {this.OverlayToggleButton({
              value: 'prohibited',
              messageId: 'search_bar-161_prohibitions',
              defaultMessage: '16.1§:n mukaiset kiellot',
              tooltipMessageId: 'search_info-prohibitions'
            })}
            {this.OverlayToggleButton({
              value: 'deleted',
              messageId: 'search_bar-deleted',
              defaultMessage: 'Poistetut',
              tooltipMessageId: 'search_info-deleted'
            })}
          </ToggleButtonGroup>
        </ButtonToolbar>
        <button
          className="search-container_search-button"
          onClick={() => this.onSubmit(this.state.searchValue, this.state.searchType)}
        >
          {<FormattedMessage id="search_bar-search" />}
        </button>
        <div className="search-container-input-container__legend">
          <CompanyCounter />
        </div>
      </div>
    );
  }
}

Search.propTypes = {
  onSearch: PropTypes.func.isRequired,
  history: PropTypes.any
};

export default withRouter(Search);
