import React from 'react';
import PropTypes from 'prop-types';
import '../styles/info-block.css';
import {FormattedMessage} from 'react-intl';

const infoBlock = props => {
  const Heading = props.headingLevel;

  return (
    <div className="info-block">
      {props.children}
      <Heading>
        <FormattedMessage id={props.title} />
      </Heading>
      <p>
        <FormattedMessage id={props.desc} />
      </p>
    </div>
  );
};

infoBlock.propTypes = {
  image: PropTypes.element,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired
};

export default infoBlock;
