import React, {Component} from 'react';
import webClient from '../api/webClient';
import Results from './SearchResultsByCompanyType';
import Company from './company';
import {queryParam} from '../helpers/queryStringHelper';
import '../styles/searchResults.css';

class SearchResults extends Component {
  state = {
    loading: false,
    companies: [],
    selectedCompany: null,
    buttonRef: null
  };

  componentDidMount() {
    const params = this.parseQueryParam();
    if (this.state.companies.length === 0 && typeof params !== 'undefined') {
      this.getCompanies();
    }
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.searchValue !== this.props.searchValue ||
      prevProps.searchType !== this.props.searchType
    ) {
      this.getCompanies();
    }
  }
  componentWillUnmount(){
    document.removeEventListener('keydown', this.escFunction, false);
  }

  escFunction = event => {
    if(event.keyCode === 27) {
      this.setState({selectedCompany: null})
    }
  }

  parseQueryParam = () => {
    return queryParam(this.props.location.search);
  };

  selectItem = (id, ref) => {
    this.setState({selectedCompany: id, buttonRef: ref});
  };

  getCompanies = () => {
    const params = this.parseQueryParam();
    const payload = {SearchKeyword: params.nameorid, Status: params.Type};
    this.setState({loading: true});
    webClient
      .post('/Yrityksenperustiedot/Etsi', payload)
      .then(response => {
        const values = response.data;
        this.setState({
          companies: values,
          loading: false
        });
      })
      .catch(error => {
        this.setState({
          loading: false
        });
      });
  };

  render() {
    let resultClassNames = 'company-table-container';
    if (this.state.selectedCompany) {
      resultClassNames += ' company-table-container-hidden';
    }
    return (
      <div className="body-container">
        <div className={resultClassNames}>
          <Results
            searchType={this.props.searchType}
            searchResultsArray={this.state.companies}
            selectItem={this.selectItem}
            loading={this.state.loading}
            selectedCompany={this.state.selectedCompany}
          ></Results>
        </div>
        {this.state.selectedCompany && (
          <Company
            companyId={this.state.selectedCompany}
            closeCompanyInfo={() => {
              this.setState({selectedCompany: null});
              this.state.buttonRef.current.focus();
            }}
            locale={this.props.locale}
          />
        )}
      </div>
    );
  }
}

export default SearchResults;
