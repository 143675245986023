import React, {useRef} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import moment from 'moment';
import CompanyTable from '../components/CompanyTable';

const registedColumns = [
  {
    name: <FormattedMessage id="company-company_name" />
  },
  {
    name: <FormattedMessage id="company-company_aux_name" />,
    className: 'no-priority'
  },
  {
    name: <FormattedMessage id="company-company_businessID" />
  },
  {
    name: <FormattedMessage id="company-address" />,
    className: 'no-priority'
  },
  {
    name: <FormattedMessage id="company-sanctions" />,
    className: 'no-priority'
  },
  {
    className: 'no-priority'
  }
];
const sanctionedColumns = [
  {
    name: <FormattedMessage id="company-company_name" />
  },
  {
    name: <FormattedMessage id="company-company_businessID" />
  },
  {
    name: <FormattedMessage id="company-sanction" />,
    className: 'no-priority'
  },
  {
    name: <FormattedMessage id="company-verdict_date" />,
    className: 'no-priority'
  },
  {
    name: <FormattedMessage id="company-docket" />,
    className: 'no-priority'
  },
  {
    name: <FormattedMessage id="company-force_of_law" />,
    className: 'no-priority'
  },
  {
    className: 'no-priority'
  }
];
const prohibitedColumns = [
  {
    name: <FormattedMessage id="company-company_name" />
  },
  {
    name: <FormattedMessage id="company-company_aux_name" />
  },
  {
    name: <FormattedMessage id="company-company_businessID" />,
    className: 'no-priority'
  },
  {
    name: <FormattedMessage id="company-denial_verdict_date" />,
    className: 'no-priority'
  },
  {
    name: <FormattedMessage id="company-docket" />,
    className: 'no-priority'
  },
  {
    name: <FormattedMessage id="company-force_of_law" />,
    className: 'no-priority'
  },
  {
    className: 'no-priority'
  }
];

const deletedColumns = [
  {
    name: <FormattedMessage id="company-company_name" />
  },
  {
    name: <FormattedMessage id="company-company_aux_name" />
  },
  {
    name: <FormattedMessage id="company-company_businessID" />,
    className: 'no-priority'
  },
  {
    name: <FormattedMessage id="company-deleted" />,
    className: 'no-priority'
  },
  {
    name: <FormattedMessage id="company-deletion_reason" />,
    className: 'no-priority'
  },
  {
    className: 'no-priority'
  }
];

const getSanctiontype = sanctionTypeSt => {
  switch (sanctionTypeSt) {
    case 'Warning':
      return 'Varoitus';
    default:
      return null;
  }
};

const getForceOfLawMessageFormat = value => {
  if (value === true) {
    return {
      id: 'sanctions-force_of_law_true'
    };
  } else {
    return {
      id: 'sanctions-force_of_law_false'
    };
  }
};

const RegisteredCompaniesRender = (item, selectedCompany, selectItem, locale) => {
  const itemClassName =
    selectedCompany === item.Id ? 'company-table__item selected' : 'company-table__item';

  const buttonRef = useRef(null);
  
  return (
    <tr key={item.Id} className={itemClassName} onClick={() => selectItem(item.Id, buttonRef)}>
      <td>{item.Toiminimi}</td>
      <td className="no-priority">{item.Aputoiminimi}</td>
      <td className="td_size">{item.YTunnus}</td>
      <td className="no-priority">{item.Osoite}</td>
      <td className="no-priority">
        {locale === 'fi' ? item.Sanktio?.TypeLabelFi : item.Sanktio?.TypeLabelSv}
      </td>
      <td className="no-priority">
        <button className="company-table__item_button" ref={buttonRef} onClick={() => selectItem(item.Id, buttonRef)}>
          <FormattedMessage id="company-open_info" />
        </button>
      </td>
    </tr>
  );
};
const SanctionedCompaniesRender = (item, selectedCompany, selectItem, locale, formatMessage) => {
  const itemClassName =
    selectedCompany === item.Id ? 'company-table__item selected' : 'company-table__item';
  
  const buttonRef = useRef(null);

  return (
    <tr key={item.Id} className={itemClassName} onClick={() => selectItem(item.Id, buttonRef)}>
      <td>{item.Toiminimi}</td>
      <td>{item.YTunnus}</td>
      <td className="no-priority">
        {locale === 'fi' ? item.Sanktio?.TypeLabelFi : item.Sanktio?.TypeLabelSv}
      </td>
      <td className="no-priority">{moment(item.SanktioPvm).format('DD.MM.YYYY')}</td>
      <td className="no-priority">{item.Sanktio?.DiaariNumber}</td>
      <td className="no-priority">
        {item.Sanktio?.Legal !== null
          ? formatMessage(getForceOfLawMessageFormat(item.Sanktio?.Legal))
          : item.Sanktio?.Legality}
      </td>
      <td className="no-priority">
        <button className="company-table__item_button" ref={buttonRef} onClick={() => selectItem(item.Id, buttonRef)}>
          <FormattedMessage id="company-open_info" />
        </button>
      </td>
    </tr>
  );
};

const ProhibitedCompaniesRender = (item, selectedCompany, selectItem, locale, formatMessage) => {
  const itemClassName =
    selectedCompany === item.Id ? 'company-table__item selected' : 'company-table__item';
  
  const buttonRef = useRef(null);
  return (
    <tr key={item.Id} className={itemClassName} onClick={() => selectItem(item.Id, buttonRef)}>
      <td>{item.Toiminimi}</td>
      <td>{item.Aputoiminimi}</td>
      <td className="no-priority">{item.YTunnus}</td>
      <td className="no-priority">{moment(item.Sanktio?.DecisionDate).format('DD.MM.YYYY')}</td>
      <td className="no-priority">{item.Sanktio?.DiaariNumber}</td>
      <td className="no-priority">
        {item.Sanktio?.Legal !== null
          ? formatMessage(getForceOfLawMessageFormat(item.Sanktio?.Legal))
          : item.Sanktio?.Legality}
      </td>
      <td className="no-priority">
        <button className="company-table__item_button" ref={buttonRef} onClick={() => selectItem(item.Id, buttonRef)}>
          <FormattedMessage id="company-open_info" />
        </button>
      </td>
    </tr>
  );
};

const DeletedCompaniesRender = (item, selectedCompany, selectItem, locale) => {
  const itemClassName =
    selectedCompany === item.Id ? 'company-table__item selected' : 'company-table__item';
  
  const buttonRef = useRef(null);
  return (
    <tr key={item.Id} className={itemClassName} onClick={() => selectItem(item.Id, buttonRef)}>
      <td>{item.Toiminimi}</td>
      <td>{item.Aputoiminimi}</td>
      <td className="no-priority">{item.YTunnus}</td>
      <td className="no-priority">{moment(item.Poistotiedot.Pvm).format('DD.MM.YYYY')}</td>
      <td className="no-priority">
        {locale === 'fi' ? item.Poistotiedot?.PoistotapaFi : item.Poistotiedot?.PoistotapaSv}
      </td>
      <td className="no-priority">
        <button className="company-table__item_button" ref={buttonRef} onClick={() => selectItem(item.Id, buttonRef)}>
          <FormattedMessage id="company-open_info" />
        </button>
      </td>
    </tr>
  );
};

const SearchResults = props => {
  let columns, renderSearchResults;
  const intl = useIntl();

  switch (props.searchType) {
    case 'registered':
      columns = registedColumns;
      renderSearchResults = RegisteredCompaniesRender;
      break;
    case 'sanctioned':
      columns = sanctionedColumns;
      renderSearchResults = SanctionedCompaniesRender;
      break;
    case 'prohibited':
      columns = prohibitedColumns;
      renderSearchResults = ProhibitedCompaniesRender;
      break;
    case 'deleted':
      columns = deletedColumns;
      renderSearchResults = DeletedCompaniesRender;
      break;
    default:
      return null;
  }

  return (
    <CompanyTable
      columns={columns}
      renderSearchResults={renderSearchResults}
      locale={intl.locale}
      formatMessage={intl.formatMessage}
      {...props}
    />
  );
};

export default SearchResults;
