import React, {Component} from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import {Layout} from './hoc/layout';
import Home from './Views/home';
import SearchResults from './Views/SearchResults';
import Yritysnakyma from './Views/company';
import Header from './components/header';
import Footer from './components/footer';
import {IntlProvider} from 'react-intl';
import messages_fi from './static/locales/fi.json';
import messages_sv from './static/locales/sv.json';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './styles/common.css';

const messages = {
  fi: messages_fi,
  sv: messages_sv
};

export default class App extends Component {
  state = {
    languageMessages: messages.fi,
    language: 'fi',
    searchValue: null,
    searchType: null
  };

  componentDidMount() {
    window.document.documentElement.lang = 'fi-FI';
  }

  onLanguageChange = newLang => {
    if (newLang === 'fi-FI') {
      this.setState({languageMessages: messages.fi, language: 'fi'});
      window.document.documentElement.lang = newLang;
    } else if (newLang === 'sv-FI') {
      this.setState({languageMessages: messages.sv, language: 'sv'});
      window.document.documentElement.lang = newLang;
    }
  };

  onSearch = (value, type) => {
    this.setState({searchValue: value, searchType: type});
  };

  render() {
    return (
      <IntlProvider locale={this.state.language} messages={this.state.languageMessages}>
        <BrowserRouter>
          <div className="app_wrapper">
            <Header
              language={this.state.language}
              onLanguageChange={this.onLanguageChange}
              title={document.title}
              headingLevel="h1"
            />
            <Layout onSearch={this.onSearch}>
              <Route path="/" exact render={props => <Home {...props} />} />
              <Route
                path="/yritykset"
                render={props => (
                  <SearchResults
                    {...props}
                    searchValue={this.state.searchValue}
                    searchType={this.state.searchType}
                    locale={this.state.language}
                  />
                )}
              />
            </Layout>
            <Route path="/yritykset/:id" render={props => <Yritysnakyma {...props} />} />
          </div>
          <Footer />
        </BrowserRouter>
      </IntlProvider>
    );
  }
}
