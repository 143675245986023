import React from "react";
import webclient from "../api/webClient";
import { FormattedMessage } from "react-intl";

class CompanyCounter extends React.Component {
  state = {
    companycount: null,
  };

  componentDidMount() {
    this.getCompanyCount();
  }
  getCompanyCount() {
    webclient.get("/Yrityksenperustiedot/HaeLkm").then(response => {
      const count = response.data;
      this.setState({
        companycount: count,
      });
    });
  }

  render() {
    return (
      <div>
        <FormattedMessage
          id="company_counter-company_count"
          defaultMessage="Rekisteriin merkittyjen yritysten määrä: "
        />
        {this.state.companycount}
      </div>
    );
  }
}

export default CompanyCounter;
