import React from 'react';
import PropTypes from 'prop-types';
import InfoRow from '../../components/apukomponentit/yritysrivi';

const Toimipaikat = ({toimipaikat}) =>
  toimipaikat.map((toimipaikka, index) => {
    const locationValue = `${toimipaikka.Katuosoite}, ${toimipaikka.Postinumero} ${toimipaikka.Postitoimipaikka}`;
    if (index === 0) {
      return <InfoRow key={index} labelId="company-offices" arvo={locationValue} />;
    }
    return <InfoRow key={index} arvo={locationValue} />;
  });

Toimipaikat.propTypes = {
  toimipaikat: PropTypes.array
};

export default Toimipaikat;
