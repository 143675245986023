import React from 'react';
import {Link} from 'react-router-dom';
import logo_fi from '../images/logo_fi.svg';
import logo_sv from '../images/logo_sv.svg';
import '../styles/header.css';
import {FormattedMessage, useIntl} from 'react-intl';
import LanguageSwitch from './LanguageSwitch';

const Header = props => {
  const intl = useIntl();

  const {headingLevel, title} = props;
  const Title = headingLevel;

  return (
    <header className="header">
      <Title style={{display: 'none'}}>{title}</Title>
      <LanguageSwitch language={props.language} onLanguageChange={props.onLanguageChange} />
      <div className="header__nav">
        <div>
          <Link to="/">
            <img
              className={intl.locale === 'fi' ? 'logo__fi' : 'logo__sv'}
              alt="Aluehallintovirasto"
              src={intl.locale === 'fi' ? logo_fi : logo_sv}
            />
          </Link>
        </div>

        <div className="header__text">
          <FormattedMessage
            id="header-header_title"
            defaultMessage="Etelä-Suomen aluehallintoviraston ylläpitämä rekisteri perintätoiminnan harjoittajista"
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
